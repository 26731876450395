// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/routes/_index.tsx"
);
import.meta.hot.lastModified = "1727460864859.352";
}
// REMIX HMR END

import Store, { loader as storeLoader } from "~/routes/_store";

export const loader = storeLoader;
export default Store;
